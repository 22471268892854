<template>
  <div v-if="visibleReal" @closed="closed" class="popTire">
    <div class="content">
      <div class="headTitle">
        <h1>设备绑定</h1>
        <span class="close" @click="closed"><i class="el-icon-close"></i></span>
      </div>
      <div class="center">
        <ul class="path" v-if="step===1">
          <li v-for="(item,index) in option" :key="index" @click="hanldeWheel(item.num)"
            :class="wheel===item.num?'active':''">
            <div class="img">
              <img src="../../../assets/images/motos.png" alt="" v-if="item.num===2">
              <img src="../../../assets/images/car2.png" alt="" v-if="item.num===4">
              <img src="../../../assets/images/van2.png" alt="" v-if="item.num===5">
            </div>
            <h5>{{item.name}}</h5>
          </li>
        </ul>
        <!-- :rules="{required: true, message: '传感器ID不能为空', trigger: 'blur'}" -->
        <div class="form" v-if="step===2">
          <el-form ref="refForm" :model="form" status-icon :rules="rules" label-position="top">
            <div class="top" :class="className">
              <div :class="`cell m${item.TyreNo}`" v-for="(item,index) in form.list" :key="index">
                <el-form-item :prop="'list.' + index + '.SensorID'" :rules="rules.SensorID">
                  <el-input v-model="item.SensorID" auto-complete="off" minlength="12" maxlength="12"
                    placeholder="请输入传感器ID">
                  </el-input>
                </el-form-item>
              </div>
              <div class="img" v-if="wheel===2"><img src="../../../assets/images/moto.png" alt="" class="in"></div>
              <div class="img" v-if="wheel===4"><img src="../../../assets/images/car2.png" alt="" class="in"></div>
              <div class="img" v-if="wheel===5"><img src="../../../assets/images/van2.png" alt="" class="in"></div>
            </div>
            <div class="item">
              <h4>报警阈值设置</h4>
            </div>
            <div class="bottom">
              <div class="cell">
                <el-form-item prop="item.tryeThres_Temperature" label="报警温度">
                  <el-input v-model="form.item.tryeThres_Temperature" auto-complete="off" minlength="3" maxlength="6">
                    <span slot="suffix" class="ico">℃</span>
                  </el-input>
                </el-form-item>
              </div>
              <div class="cell">
                <el-form-item prop="item.tryeThres_LPress" label="胎压（低）">
                  <el-input v-model="form.item.tryeThres_LPress" auto-complete="off" minlength="3" maxlength="6">
                    <span slot="suffix" class="ico">kpa</span>
                  </el-input>
                </el-form-item>
              </div>
              <div class="cell">
                <el-form-item prop="item.tryeThres_HPress" label="胎压（高）">
                  <el-input v-model="form.item.tryeThres_HPress" auto-complete="off" minlength="3" maxlength="6">
                    <span slot="suffix" class="ico">kpa</span>
                  </el-input>
                </el-form-item>
              </div>
            </div>

          </el-form>
          <div class="btn">
            <el-button plain type="primary" size="medium" :loading="btnLoading" @click="handlerSubmit">提交</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import * as API from '@/api/common'
  import {
    sortPostion
  } from '@/common/utils'
  export default {
    props: {
      upgrade: {
        type: Number,
        default: 15
      },
    },
    data() {
      let validateSenid = (rule, value, callback) => {
        if (value.length !== 12) {
          callback(new Error('请输入12位传感器ID'));
        } else {
          callback();
        }
      }
      return {
        id: 0,
        wheel: 2,
        className: 'moto',
        option: [{
            name: '两轮',
            num: 2
          },
          {
            name: '四轮',
            num: 4
          },
          {
            name: '五轮',
            num: 5
          }
        ],
        visibleReal: false,
        step: 1,
        btnLoading: false,
        form: {
          list: [],
          item: {}
        },
        rules: {
          SensorID: [{
              required: true,
              message: '传感器ID不能为空',
              trigger: 'blur'
            },
            {
              validator: validateSenid,
              trigger: 'blur'
            }
          ],
          'item.tryeThres_Temperature': [{
            required: true,
            message: '请设置报警温度',
            trigger: 'blur'
          }],
          'item.tryeThres_LPress': [{
            required: true,
            message: '请设置胎压（低）',
            trigger: 'blur'
          }],
          'item.tryeThres_HPress': [{
            required: true,
            message: '请设置胎压（高）',
            trigger: 'blur'
          }]
        }
      }
    },
    watch: {
      upgrade(next, pre) {
        if (next && next != pre) {
          if (this.visibleReal && next === 1) {
            this.fetchData(this.device.ObjectID, this.device)
          }
        }
      }
    },
    methods: {
      open(data) {
        this.$nextTick(() => {
          console.log(data)
          this.id = data.id
          this.visibleReal = true
          if (data.edit) {
            this.step = 2
            this.fetchData(data.id)
          } else {
            this.step = 1
          }
        })
      },
      closed() {
        this.visibleReal = false
      },
      hanldeWheel(e) {
        this.wheel = e
        this.step = 2
        this.className = e === 2 ? 'moto' : (e === 4 ? 'car' : 'van')
        this.creatData(this.id)
      },
      creatData(id) {
        const len = this.wheel
        let temp = []
        const valve = {
          tryeThres_HPress: 310,
          tryeThres_LPress: 200,
          tryeThres_Temperature: 80
        }
        for (let j = 0; j < len; j++) {
          let obj = {
            DragID: 0,
            ObjectID: 0,
            RecID: 0,
            SensorID: "",
            TyreNo: 1,
          }
          obj.ObjectID = id
          obj.TyreNo = j + 1
          temp.push(obj)
        }
        this.form.item = valve
        this.form.list = temp
      },
      fetchData(id) {
        API.GetBindTyreList(id).then((ret) => {
          if (ret.data.errCode === 0) {
            const data = ret.data.data
            const item = data.item
            let sensoridData = data.list
            const len = this.wheel
            let temp = []
            const valve = {
              tryeThres_HPress: item.tryeThres_HPress ? item.tryeThres_HPress : 310,
              tryeThres_LPress: item.tryeThres_LPress ? item.tryeThres_LPress : 200,
              tryeThres_Temperature: item.tryeThres_Temperature ? item.tryeThres_Temperature : 80
            }
            this.form.item = valve
            if (sensoridData.length) {
              console.log(sensoridData)
              let e = sensoridData.length ? sensoridData.length : 2
              this.wheel = e
              this.className = e === 2 ? 'moto' : (e === 4 ? 'car' : 'van')
              sensoridData.forEach(item => {
                item.TyreNo = parseInt(item.TyreNo)
              })
              this.form.list = sensoridData
            } else {
              for (let j = 0; j < len; j++) {
                let obj = {
                  DragID: 0,
                  ObjectID: 0,
                  RecID: 0,
                  SensorID: "",
                  TyreNo: 1,
                }
                obj.ObjectID = id
                obj.TyreNo = j + 1
                temp.push(obj)
              }
              this.form.list = temp
            }
          }
        })
      },
      handlerSubmit() {
        this.btnLoading = true
        const id = this.id
        const item = this.form.item
        const list = this.form.list
        const promise = new Promise((resolve, reject) => {
          this.$refs.refForm.validate(valid => {
            if (valid) {
              API.SetTyreInfo(id, list, item).then((ret) => {
                const d = ret.data
                console.log(d)
                console.log(d.errCode)
                if (d.errCode === 0) {
                  this.$message({
                    type: 'success',
                    message: '胎压传感器绑定成功'
                  })
                  this.visibleReal = false
                } else {
                  this.$message({
                    type: 'error',
                    message: d.errMsg
                  })
                }

                resolve()
              })
            } else {
              reject(false)
            }
            this.btnLoading = false
          })
        })

        return promise
      },
    },
    mounted() {

    }
  }

</script>
<style lang="scss" scoped>
  .popTire {
    position: fixed;
    right: 20px;
    bottom: 60px;
    z-index: 3;
  }

  .headTitle {
    height: 34px;
    padding: 0 15px;

    h1 {
      text-indent: 15px;
      line-height: 34px;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #111111;
      position: relative;
      z-index: 1;
    }



    // h1::before {
    //   content: '';
    //   display: block;
    //   width: 6px;
    //   height: 6px;
    //   border-radius: 50%;
    //   background-color: #1890ff;
    //   position: absolute;
    //   top: 16px;
    //   left: 0px;
    // }

    .close {
      padding: 8px;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 3;
      cursor: pointer;
    }

    .close:hover {
      color: #111111;
    }
  }

  .content {
    width: 480px;
    height: 400px;
    background: #FFFFFF;
    border: 1px solid #A1ACB7;
    border-radius: 8px;
  }

  .path {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20px;

    li {
      width: 208px;
      height: 150px;
      background: #FFFFFF;
      border: 2px solid #D8D8D8;
      border-radius: 6px;
      margin-right: 20px;
      margin-bottom: 30px;
      cursor: pointer;

      .img {
        width: 168px;
        height: 86px;
        margin: 15px auto;

        img {
          width: 100%;
        }
      }

      h5 {
        text-align: center;
        font-size: 16px;
        color: #939393;
      }
    }

    li::nth-child(2n+1) {
      margin-right: 0;
    }

    .active {
      border: 2px solid #1890FF;
    }
  }

  .top {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 200px;

    .cell {
      position: absolute;
      z-index: 2;
    }
  }

  .moto {
    .img {
      width: 210px;
      height: 140px;
      margin: 0 auto;

      .in {
        width: 90%;
        margin-top: 45px;
      }
    }

    .cell::after {
      content: '';
      width: 40px;
      height: 120px;
      border: solid 1px #dbdbdb;
      display: block;
      position: absolute;
      z-index: -1;
    }

    .cell::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #dbdbdb;
      position: absolute;
      z-index: 2;
    }

    .m1 {
      left: 0;
      top: 0;
    }

    .m1::after {
      top: 22px;
      left: 90px;
      border-top: none;
      border-right: none;
    }

    .m1::before {
      bottom: -83px;
      left: 128px;
    }

    .m2 {
      right: 0;
      top: 0;
    }

    .m2::after {
      top: 22px;
      left: 50px;
      border-top: none;
      border-left: none;
    }

    .m2::before {
      bottom: -83px;
      left: 45px;
    }
  }

  .car {
    .img {
      width: 210px;
      height: 109px;
      margin: 0 auto;

      .in {
        width: 90%;
        margin-top: 45px;
      }
    }

    .cell::after {
      content: '';
      width: 74px;
      height: 30px;
      border: solid 1px #dbdbdb;
      display: block;
      position: absolute;
      z-index: -1;
    }

    .cell::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #dbdbdb;
      position: absolute;
      z-index: 2;
    }

    .m1 {
      left: 0;
      top: 0;
    }

    .m1::after {
      bottom: -3px;
      left: 100px;
      border-top: none;
      border-right: none;
    }

    .m1::before {
      bottom: -6px;
      left: 170px;
    }

    .m2 {
      right: 0;
      top: 0;
    }

    .m2::before {
      bottom: -6px;
      left: 6px;
    }

    .m2::after {
      bottom: -3px;
      left: 10px;
      border-top: none;
      border-left: none;
    }


    .m3 {
      left: 0;
      bottom: -5px;
    }

    .m3::before {
      top: -23px;
      left: 172px;
    }

    .m3::after {
      top: -20px;
      left: 100px;
      border-bottom: none;
      border-right: none;
    }

    .m4 {
      right: 0;
      bottom: -5px;
    }

    .m4::before {
      top: -23px;
      left: 6px;
    }

    .m4::after {
      top: -20px;
      left: 10px;
      border-bottom: none;
      border-left: none;
    }
  }

  .van {
    .img {
      width: 210px;
      height: 109px;
      margin-left: 70px;

      .in {
        width: 80%;
        margin-top: 45px;
      }
    }

    .cell {
      width: 190px;
    }

    .cell::after {
      content: '';
      width: 74px;
      height: 30px;
      border: solid 1px #dbdbdb;
      display: block;
      position: absolute;
      z-index: -1;
    }

    .cell::before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #dbdbdb;
      position: absolute;
      z-index: 2;
    }

    .m1 {
      left: 30px;
      top: 0;
    }

    .m1::after {
      width: 40px;
      bottom: 6px;
      left: 34px;
      border-top: none;
      border-right: none;
    }

    .m1::before {
      bottom: 4px;
      left: 73px;
    }

    .m2 {
      right: 0;
      top: 0;
    }

    .m2::before {
      bottom: 9px;
      left: -86px;
    }

    .m2::after {
      width: 170px;
      bottom: 11px;
      left: -84px;
      border-top: none;
      border-left: none;
    }


    .m3 {
      left: 30px;
      bottom: -5px;
    }

    .m3::before {
      top: -29px;
      left: 73px;
    }

    .m3::after {
      width: 40px;
      top: -26px;
      left: 34px;
      border-bottom: none;
      border-right: none;
    }

    .m4 {
      right: 30px;
      bottom: -5px;
    }

    .m4::before {
      top: -23px;
      left: -55px;
    }

    .m4::after {
      width: 170px;
      top: -20px;
      left: -54px;
      border-bottom: none;
      border-left: none;
    }

    .m5 {
      right: 0;
      top: 60px;
    }

    .m5::before {
      top: 22px;
      left: -34px;
    }

    .m5::after {
      height: 1px;
      top: 24px;
      left: -32px;
      border-bottom: none;
      border-left: none;
    }

  }

  .form {
    margin: 0 11px;

    .item {
      border-top: 1px solid #DCDCDC;

      h4 {
        padding-top: 10px;
        font-size: 16px;
        // font-weight: 400;
        color: #111111;
      }
    }

    .bottom {
      display: flex;

      .cell {
        margin-right: 10px;

        & ::v-deep .el-form-item {
          margin-bottom: 10px;
        }

        & ::v-deep .el-form-item__label {
          padding: 0;
          line-height: 30px;
        }

        .ico {
          color: #111111;
        }
      }

      .cell:last-child {
        margin-right: 0;
      }
    }
  }

  .btn {
    text-align: center;

    button {
      width: 180px;
      height: 36px;
      margin: 0 auto;
    }
  }

</style>
