import {
  remoteLoad,
  findFromArray,
  deleteFromArray,
  debounce,
  bd_encrypt
} from '@/common/utils'
import {
  mapGetters,
  mapMutations
} from 'vuex'
import miment from 'miment'
import * as API from '@/api/common'
import {
  getLanguage
} from '@/common/cache'
import {
  glBmap
} from '@/store/getters'

export default {
  created() {

  },
  data() {
    return {
      miment,
      mapLayer: '',
      map: null,
      mimap:null
    }
  },
  watch: {
    $route(val, oldVal) {
      if (val.path === '/online/monitor') {
        this.refreshNow()
        if (oldVal && oldVal.path !== '/online/monitor') {
          this.$nextTick(function () {
            this.map && this.map.setCenter([this.map.getCenter().getLng() + 0.000001, this.map.getCenter().getLat() +
              0.000001
            ])
          })
        }
        if (val.query.action === 'addTaskList') {
          if (val.query.objectid) {
            if (val.query.hasOwnProperty('clear')) {
              this.removeAllData()
            }
            this.addTaskListOne(val.query.objectid)
          } else {
            this.addTaskList()
          }
          this.$router.replace('/online/monitor')
        }
      } else {
        this.isShowVideo = false;
        this.end_timer()
        this.timer_activeAlarmList && clearInterval(this.timer_activeAlarmList)
      }
    },
    'table.viewTable': {
      deep: true,
      handler(val) {
        if (val === 2 && this.alarmList.length === 0) {
          this.fetchAlarmList()
        } else {
          this.timer_activeAlarmList && clearInterval(this.timer_activeAlarmList)
        }
      }
    },
    iframeLoading(val) {
      if (val) {
        this.iframeLoading_timer = setTimeout(() => {
          this.iframeLoading = false
          this.iframeLoading_timer = null
        }, 15000)
      } else {
        if (this.iframeLoading_timer) {
          clearTimeout(this.iframeLoading_timer)
          this.iframeLoading_timer = null
        }
      }
    },
    'iframe.src': {
      deep: true,
      handler(val) {
        if (val != '#' && val != 'about:blank') {
          this.iframeLoading = true
        }
      }
    },
    isShowAlarmRegion(val) {
      if (!val) {
        this.map.clearInfoWindow()
      }
      return val ? this.addRegion(this.map) : this.removeRegion()
    },
    isShowPoi(val) {
      if (!val) {
        this.map.clearInfoWindow()
      }
      return val ? this.addPoi(this.map) : this.removePoi()
    },
    list_length(val, oldVal) {
      if (val === 0 && oldVal > 0) {
        // 关闭计时器
        this.end_timer()
      } else if (val > 0 && oldVal === 0) {
        // 打开计时器
        this.start_timer()
      }
    },
    map3D(val) {
      if (val) {
        this.map.setPitch(80)
        this.map.setRotation(1, true);
      } else {
        this.map.setPitch(0)
      }
    }
  },
  data() {
    return {
      map: null,
      infoWindow: null
    }
  },
  methods: {
    ifNotExistsWindow() {
      if (!this.infoWindow) {
        let infoWindow = new this.AMap.InfoWindow({
          isCustom: true,
          content: '',
          offset: new this.AMap.Pixel(0, -30)
        })
        infoWindow.on('close', () => {})
        // 根据getId获取当前监控车辆
        infoWindow.setId = id => infoWindow._id = id
        infoWindow.getId = () => infoWindow._id
        this.infoWindow = infoWindow
      }
    },
    ifNotExistsWindowBd() {
      let infoWindow = new this.BMap.InfoWindow('', {
        width: 0,
        height: 0,
        offset: new this.BMap.Size(0, -52),
        enableCloseOnClick: false,
        enableAutoPan: false
      })
      // 地图
      infoWindow.map = this.map

      // 根据getId获取当前监控车辆
      infoWindow.setId = id => infoWindow._id = id
      infoWindow.getId = () => infoWindow._id
      this.infoWindow = infoWindow
    },
    ifNotExistsWindowBd2() {
      let panel = ''
      this.infoWindow = new window.BMapGLLib.InfoBox(this.map, panel, {
        boxStyle: {
          width: "460px",
        },
        closeIconUrl: "lib/close.png",
        closeIconMargin: "1px 1px 0 0",
        enableAutoPan: false,
        offset: new this.BMap.Size(0, 40),
        align: INFOBOX_AT_TOP
      })
      this.infoWindow.getId = () => {
        return null
      }
    },
    createInfowindow(panel, id) {
      this.infoWindow && this.infoWindow.close()
      this.infoWindow = new window.BMapGLLib.InfoBox(this.map, panel, {
        boxStyle: {
          width: "460px",
        },
        closeIconUrl: "lib/close.png",
        closeIconMargin: "1px 1px 0 0",
        enableAutoPan: false,
        offset: new this.BMap.Size(0, 40),
        align: INFOBOX_AT_TOP
      })
      this.infoWindow.getId = () => {
        return id || null
      }
    },
    updataInfowindowByData(row) {
      const promise = new Promise(async (resolve) => {
        const reuslt = await this.fetchOneDeviceData2(row.ObjectID, row.VehicleID)
        const device = reuslt[0]
        // console.log(device)
        this.updateDevice = reuslt
        const index = this.list.findIndex(k => this.diffCompare(k, device)) // 图标是否存在于地图
        if (~index) { // 更新地图图标和信息窗状态// 列表信息
          const item = findFromArray(this.list, k => this.diffCompare(k, device))
          item.data = device
          item.ObjectID = device.ObjectID
          item.marker.setIcon(new window.AMap.Icon({
            size: new window.AMap.Size(this.iconSize, this.iconSize),
            image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
          }))
          item.marker.setAngle(this.getIconAngle(device.VehicleImageUrl, device.Direct))
          item.marker.setPosition([device.Lon, device.Lat])
          item.marker_text.setPosition([device.Lon, device.Lat])
          if (this.infoWindow.getId() == device.ObjectID) {
            this.infoWindow.setContent(this.addDataToMap_getPanel(device))
            this.infoWindow.setPosition([device.Lon, device.Lat])
            this.map.setCenter([device.Lon, device.Lat])
          }
          this.list = Object.assign(this.list, [item])
        }
        // this.alarmList.forEach((k, eq) => { //更新报警列表1
        //   if (k.ObjectID === row.ObjectID) {
        //     // console.log(eq).filter
        //     this.alarmList.splice(eq, 1)
        //   }
        // })
        // this.alarmList = this.alarmList.filter(k => k.ObjectID!==row.ObjectID) //更新报警列表2
        if (this.table.show && this.table.viewTable === 2) {
          this.fetchAlarmList() //更新报警列
        }
        this.$refs.deviceNum.fetchSummar() // 更新统计数据
        // this.fetchAlarmList()
        // this.fetchAddress([device[0]],[device[0]]).then(list=>{
        //   this.addDataToMap(list[0],autoFresh,enforce)
        // })
        resolve()
      })
      return promise
    },
    updataInfowindowByData_bd(row) {
      const promise = new Promise(async (resolve) => {
        const reuslt = await this.fetchOneDeviceData2(row.ObjectID, row.VehicleID)
        const device = reuslt[0]
        // console.log(device)
        this.updateDevice = reuslt
        const index = this.list.findIndex(k => this.diffCompare(k, device)) // 图标是否存在于地图
        if (~index) { // 更新地图图标和信息窗状态// 列表信息
          const item = findFromArray(this.list, k => this.diffCompare(k, device))
          item.data = device
          item.ObjectID = device.ObjectID

          item.marker.setIcon(new this.BMap.Icon(require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
            new this.BMap.Size(this.iconSize, this.iconSize), {
              anchor: new this.BMap.Size(this.iconSize / 2, this.iconSize / 2),
              imageSize: new this.BMap.Size(this.iconSize, this.iconSize)
            }))
          item.marker.setRotation(this.getIconAngle(device.VehicleImageUrl, device.Direct))
          item.marker.setPosition(new this.BMap.Point(device.Lon, device.Lat))
          item.marker_text.setPosition(new this.BMap.Point(device.Lon, device.Lat))

          // if(this.glBmap){
          //   this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          //   // this.infoWindow.open(new this.BMap.Point(device.Lon, device.Lat))
          // }else{

          // }
          this.infoWindow.setContent(this.addDataToMap_getPanel(device))
          this.infoWindow.setPosition([device.Lon, device.Lat])
          if (!this.map.getBounds().containsPoint(new this.BMap.Point(device.Lon, device.Lat))) {
            this.map.setCenter(new this.BMap.Point(device.Lon, device.Lat))
          }
          this.list = Object.assign(this.list, [item])
        }
        // this.alarmList.forEach((k, eq) => { //更新报警列表1
        //   if (k.ObjectID === row.ObjectID) {
        //     // console.log(eq).filter
        //     this.alarmList.splice(eq, 1)
        //   }
        // })
        // this.alarmList = this.alarmList.filter(k => k.ObjectID!==row.ObjectID) //更新报警列表2
        if (this.table.show && this.table.viewTable === 2) {
          this.fetchAlarmList() //更新报警列
        }
        this.$refs.deviceNum.fetchSummar() // 更新统计数据
        // this.fetchAlarmList()
        // this.fetchAddress([device[0]],[device[0]]).then(list=>{
        //   this.addDataToMap(list[0],autoFresh,enforce)
        // })
        resolve()
      })
      return promise
    },
    getSimplePanel(title, datas, padding, others = {}) {
      let panel = this.$dataPanel.create(Object.assign({
        bgColor: true,
        title: title,
        datas,
        paddingLeft: padding,
        showMore: false,
        showHeader: false,
        close: true,
        lon: others.lon,
        lat: others.lat,
        onClose: () => {
          if (this.glBmap) {
            this.infoWindow.close()
          } else {
            this.map.clearInfoWindow()
          }
        },
        onChange: () => {}
      }, others))
      return panel
    },
    showRealScene(lng, lat, heading, objectId) {
      if (this.infoWindow.getId() != objectId) {
        return
      }
      let data = {
        Lng: bd_encrypt(lng, lat).bd_lng,
        Lat: bd_encrypt(lng, lat).bd_lat,
        Direct: heading
      }
      if (this.currentMap === 'Bmap') {
        data.Lng = bd_encrypt(lng, lat).bd_lng
        data.Lat = bd_encrypt(lng, lat).bd_lat
      }

      this.isShowRealScene = true
      let jiejing = this.$refs['mainIframe']
      jiejing.contentWindow.postMessage(data, 'https://www.365qczx.com/Jiejing/StreetBD2.html');
    },
    addDataToMap_add(device, isAutoRefresh) {
      var marker = new this.AMap.Marker({
        icon: new window.AMap.Icon({
          size: new window.AMap.Size(this.iconSize, this.iconSize),
          image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
          imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
        }),
        position: [device.Lon, device.Lat],
        offset: new this.AMap.Pixel(-this.iconSize / 2, -this.iconSize / 2),
        angle: this.getIconAngle(device.VehicleImageUrl, device.Direct),
        extData: device.ObjectID,
        topWhenClick: true
      })
      var marker_text = new this.AMap.Text({
        text: this.getVehicleName(device),
        position: [device.Lon, device.Lat],
        offset: new this.AMap.Pixel(18, 0),
        extData: device.ObjectID,
        textAlign: 'left',
        topWhenClick: true,
        style: this.getTeamStyle(device.TeamColor)
      })

      if (!isAutoRefresh) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(device.ObjectID)
      } else if (this.infoWindow.getId() == device.ObjectID) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
      }

      marker.on('click', this.carMarkerClick)
      // marker.on('click', () => {
      //   panel = this.addDataToMap_getPanel(device)
      //   this.infoWindow.setContent(panel)
      //   this.infoWindow.open(this.map, marker.getPosition())
      //   this.infoWindow.setId(marker.getExtData())
      // })
      const overlays = []
      const item = {
        data: device,
        marker,
        marker_text,
        overlays,
        ObjectID: device.ObjectID,
        VehicleID: device.VehicleID
      }

      this.list.push(item)
      this.map.add([marker, marker_text])

      if (!isAutoRefresh) {
        this.map.setCenter([device.Lon, device.Lat])
        // 切换地图zoom
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      }
      // 修改实景位置
      this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
    },
    addDataToMap_change(device, isAutoRefresh, enforce) {
      this.list.forEach(k => {
        if (device.ObjectID === k.ObjectID) {
          k.data.Address = device.Address;
        }
      })
      const item = findFromArray(this.list, k => this.diffCompare(k, device))
      if (!enforce) { //
        if (item.ObjectID === device.ObjectID && item.data.RcvTime === device.RcvTime && item.data.isOnline == device
          .isOnline && isAutoRefresh) {
          return
        }
      }

      item.data = device
      item.ObjectID = device.ObjectID
      item.marker.setIcon(new window.AMap.Icon({
        size: new window.AMap.Size(this.iconSize, this.iconSize),
        image: require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
        imageSize: new window.AMap.Size(this.iconSize, this.iconSize)
      }))
      item.marker.setAngle(this.getIconAngle(device.VehicleImageUrl, device.Direct))
      item.marker.setPosition([device.Lon, device.Lat])
      item.marker_text.setPosition([device.Lon, device.Lat])
      if (!isAutoRefresh) {
        this.infoWindow.setPosition([device.Lon, device.Lat])
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.open(this.map, [device.Lon, device.Lat])
        this.infoWindow.setId(device.ObjectID)
        this.map.setCenter([device.Lon, device.Lat])
        // 切换地图zoom
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      } else if (this.infoWindow.getId() == device.ObjectID) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.setPosition([device.Lon, device.Lat])
        this.map.setCenter([device.Lon, device.Lat])
      }

      // 修改实景位置
      // this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
    },
    addDataToMap_add_bd(device, isAutoRefresh) {
      var marker = new this.BMap.Marker(new this.BMap.Point(device.Lon, device.Lat), {
        rotation: this.getIconAngle(device.VehicleImageUrl, device.Direct),
        icon: new this.BMap.Icon(require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
          new this.BMap.Size(this.iconSize, this.iconSize), {
            anchor: new this.BMap.Size(this.iconSize / 2, this.iconSize / 2),
            imageSize: new this.BMap.Size(this.iconSize, this.iconSize)
          })
      })
      marker.extData = device.ObjectID
      marker.getExtData = function () {
        return this.extData
      }
      var marker_text = new this.BMap.Label(this.getVehicleName(device), {
        position: new this.BMap.Point(device.Lon, device.Lat),
        offset: new this.BMap.Size(18, -12)
      })
      marker_text.setStyle(this.getTextStyle(device.VehicleNumBackColor))
      if (!isAutoRefresh) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(device.ObjectID)
      } else if (this.infoWindow.getId() == device.ObjectID) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
      }

      marker.on('click', () => {
        let panel = this.addDataToMap_getPanel(device)
        this.infoWindow.setContent(panel)
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(marker.getExtData())
      })

      // 添加其他覆盖物，如LBS的圆圈
      const overlays = []

      const item = {
        data: device,
        marker,
        marker_text,
        overlays,
        ObjectID: device.ObjectID,
        VehicleID: device.VehicleID
      }

      this.list.push(item)
      marker.setMap(this.map)
      marker_text.setMap(this.map)

      if (!isAutoRefresh) {
        this.map.centerAndZoom(new this.BMap.Point(device.Lon, device.Lat), 16)
        // 切换地图zoom
        // if (this.map.getZoom() < 14) {
        //   this.map.setZoom(14)
        // }
      }

      // 修改实景位置
      // this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
    },
    addDataToMap_change_bd(device, isAutoRefresh, enforce) {
      this.list.forEach(k => {
        if (device.ObjectID === k.ObjectID) {
          k.data.Address = device.Address;
        }
      })
      const item = findFromArray(this.list, k => this.diffCompare(k, device))

      if (!enforce) { // 解除报警强制更新
        if (item.ObjectID === device.ObjectID && item.data.RcvTime === device.RcvTime && item.data.isOnline == device
          .isOnline && isAutoRefresh) {
          return
        }
      }

      item.data = device
      item.ObjectID = device.ObjectID
      item.marker.setIcon(new this.BMap.Icon(require(`../../assets/mapIcons/${device.VehicleImageUrl}`),
        new this.BMap.Size(this.iconSize, this.iconSize), {
          anchor: new this.BMap.Size(this.iconSize / 2, this.iconSize / 2),
          imageSize: new this.BMap.Size(this.iconSize, this.iconSize)
        }))
      item.marker.setRotation(this.getIconAngle(device.VehicleImageUrl, device.Direct))
      item.marker.setPosition(new this.BMap.Point(device.Lon, device.Lat))

      item.marker_text.setPosition(new this.BMap.Point(device.Lon, device.Lat))
      if (!isAutoRefresh) {
        // this.infoWindow.setPosition([device.Lon, device.Lat])
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.open(this.map, [device.Lon, device.Lat])
        this.infoWindow.setId(device.ObjectID)
        this.map.setCenter(new this.BMap.Point(device.Lon, device.Lat))
        // 切换地图zoom
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      } else if (this.infoWindow.getId() == device.ObjectID) {
        this.infoWindow.setContent(this.addDataToMap_getPanel(device))
        this.infoWindow.setPosition([device.Lon, device.Lat])
        if (!this.map.getBounds().containsPoint(new this.BMap.Point(device.Lon, device.Lat))) {
          this.map.setCenter(new this.BMap.Point(device.Lon, device.Lat))
        }
      }
      // 修改实景位置
      // this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
    },
    //地球模式-百度
    addDataToMap_add_bdgl(device, isAutoRefresh) {
      var marker = new this.BMap.Marker(new this.BMap.Point(device.Lon, device.Lat), {
        rotation: this.getIconAngle(device.VehicleImageUrlEN, device.Direct),
        icon: new this.BMap.Icon(require(`../../assets/mapIcons/${device.VehicleImageUrlEN}`),
          new this.BMap.Size(this.iconSize, this.iconSize), {
            anchor: new this.BMap.Size(this.iconSize / 2, this.iconSize / 2),
            imageSize: new this.BMap.Size(this.iconSize, this.iconSize)
          })
      })
      marker.extData = device.ObjectID
      marker.getExtData = function () {
        return this.extData
      }
      var marker_text = new this.BMap.Label(this.getVehicleName(device), {
        position: new this.BMap.Point(device.Lon, device.Lat),
        offset: new this.BMap.Size(18, -12)
      })
      marker_text.setStyle(this.getTextStyle(device.VehicleNumBackColor))
      let panel = this.addDataToMap_getPanel_gl(device)
      if (!isAutoRefresh) {
        console.log('a1')
        this.createInfowindow(panel, device.ObjectID)
        this.infoWindow.open(new this.BMap.Point(device.Lon, device.Lat))
        this.map.setCenter(new this.BMap.Point(device.Lon, device.Lat))
        // 切换地图zoom
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      } else if (this.infoWindow.id == device.ObjectID) {
        console.log('a2')
      }
      marker.on('click', () => {
        this.createInfowindow(panel, device.ObjectID)
        this.infoWindow.open(new this.BMap.Point(device.Lon, device.Lat))
      })
      // 添加其他覆盖物，如LBS的圆圈
      const overlays = []
      const item = {
        data: device,
        marker,
        marker_text,
        overlays,
        ObjectID: device.ObjectID,
        VehicleID: device.VehicleID
      }
      this.list.push(item)
      marker.setMap(this.map)
      marker_text.setMap(this.map)
      // 修改实景位置
      // this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
    },
    addDataToMap_change_bdgl(device, isAutoRefresh, enforce) {
      this.list.forEach(k => {
        if (device.ObjectID === k.ObjectID) {
          k.data.Address = device.Address;
        }
      })
      const item = findFromArray(this.list, k => this.diffCompare(k, device))
      if (!enforce) { // 解除报警强制更新
        if (item.ObjectID === device.ObjectID && item.data.RcvTime === device.RcvTime && item.data.isOnline == device
          .isOnline && isAutoRefresh) {
          return
        }
      }
      item.data = device
      item.ObjectID = device.ObjectID
      item.marker.setIcon(new this.BMap.Icon(require(`../../assets/mapIcons/${device.VehicleImageUrlEN}`),
        new this.BMap.Size(this.iconSize, this.iconSize), {
          anchor: new this.BMap.Size(this.iconSize / 2, this.iconSize / 2),
          imageSize: new this.BMap.Size(this.iconSize, this.iconSize)
        }))
      item.marker.setRotation(this.getIconAngle(device.VehicleImageUrlEN, device.Direct))

      // item.marker_text = new this.BMap.Label(this.getVehicleName(device), {
      //   position: new this.BMap.Point(device.Lon, device.Lat),
      //   offset: new this.BMap.Size(18, -12)
      // })
      // item.marker_text.setStyle(this.getTextStyle(device.VehicleNumBackColor))
      // item.marker_text.setPosition(new this.BMap.Point(device.Lon, device.Lat))
      let panel = this.addDataToMap_getPanel_gl(device)
      if (!isAutoRefresh) {
        this.createInfowindow(panel, device.ObjectID)
        this.infoWindow.open(new this.BMap.Point(device.Lon, device.Lat))
        this.map.centerAndZoom(new this.BMap.Point(device.Lon, device.Lat), 16)
        if (this.map.getZoom() < 14) {
          this.map.setZoom(14)
        }
      } else if (this.infoWindow.id == device.ObjectID) {
        console.log('c2')
      }
      // if(this.infoWindow._isOpen)
      // 修改实景位置
      // this.isShowRealScene && this.showRealScene(device.Lon, device.Lat, device.Direct, device.ObjectID)
    },
    selectRegion(item) {
      const target = findFromArray(this.regionOverlays, k => k.getExtData() === item.RegionID)
      if (target) {
        this.map.setFitView([target])
      } else {
        let k = item
        let polygon = new this.AMap.Polygon({
          map: this.map,
          path: k.points.map(j => [j.Lon, j.Lat]),
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          extData: k.RegionID
        })
        polygon.getDraggable = () => false
        polygon.getPosition = () => new this.AMap.LngLat(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].Lon,
            lat: k.points[0].Lat
          }))
          this.infoWindow.open(this.map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.setId(null)
          if (this.glAmap) {
            this.infoWindow.setAnchor('bottom-center')
          }
        })

        this.regionOverlays.push(polygon)
        this.map.setFitView([polygon])
      }
    },
    selectRegion_bd(item) {
      const target = findFromArray(this.regionOverlays, k => k.getExtData() === item.RegionID)
      if (target) {
        this.setFitView_bd(this.map, [target])
      } else {
        let k = item
        let polygon = new this.BMap.Polygon(k.points.map(j => new this.BMap.Point(j.Lon, j.Lat)), {
          strokeColor: '#1890ff',
          strokeOpacity: '0.9',
          fillColor: '#1890ff',
          fillOpacity: '0.1',
          strokeWeight: 2
        })
        polygon.setMap(this.map)
        polygon.extData = k.RegionID
        polygon.getExtData = function () {
          return this.extData
        }
        polygon.getDraggable = () => false
        polygon.getPosition = () => new this.BMap.Polygon(k.points[0].Lon, k.points[0].Lat)
        polygon.on('click', () => {
          console.log(k, [k.points[0].Lon, k.points[0].Lat], this.map)
          this.infoWindow.setContent(this.getSimplePanel(k.RegionName, [{
              title: `${this.$t('onlineMonitor.regionName')}`,
              text: k.RegionName
            },
            {
              title: `${this.$t('onlineMonitor.createTime')}`,
              text: miment(k.CreateTime).format()
            }
          ], '5em', {
            lon: k.points[0].Lon,
            lat: k.points[0].Lat
          }))
          // this.infoWindow.open(this.map, [k.points[0].Lon, k.points[0].Lat])
          this.infoWindow.open(this.map, new this.BMap.Point(k.points[0].Lon, k.points[0].Lat))
          // this.map.openInfoWindow(this.infoWindow, new this.BMap.Point(k.points[0].Lon, k.points[0].Lat))
          this.infoWindow.setId(null)
        })

        this.regionOverlays.push(polygon)
        this.setFitView_bd(this.map, [polygon])
      }
    },
    selectPoi(item) {
      let map = this.map
      const target = findFromArray(this.poiOverlays, k => k.getExtData() === item.PointID)
      if (target) {
        this.map.setFitView([target])
        return
      }
      let k = item
      let marker = new this.AMap.Marker({
        map: map,
        position: [k.Lon_F, k.Lat_F],
        extData: k.PointID,
        icon: require('../../assets/icon/shadow_marker.png'),
        offset: new this.AMap.Pixel(-16, -32),
        label: {
          content: k.PointName,
          offset: new this.AMap.Pixel(32, 8)
        }
      })
      marker.on('click', () => {
        const d = k
        this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
          title: `${this.$t('onlineMonitor.lngLat')}`,
          text: d.Lon + ',' + d.Lat
        }], '5em', {
          links: [this.$t('onlineMonitor.removeFavirite')],
          onLinkClick: () => {
            this.deletePoi(d)
          },
          lon: d.Lon,
          lat: d.Lat
        }))
        this.infoWindow.open(this.map, marker.getPosition())
        this.infoWindow.setId(null)
        if (this.glAmap) {
          this.infoWindow.setAnchor('bottom-center')
        }
      })
      this.poiOverlays.push(marker)
      this.map.setFitView([marker])
    },
    selectPoi_bd(item) {
      let map = this.map
      const target = findFromArray(this.poiOverlays, k => k.getExtData() === item.PointID)
      if (target) {
        this.setFitView_bd(this.map, [target])
        return
      }
      let k = item
      let marker = new this.BMap.Marker(new this.BMap.Point(k.Lon_F, k.Lat_F), {
        icon: new this.BMap.Icon(require('../../assets/icon/shadow_marker.png'),
          new this.BMap.Size(32, 32), {
            anchor: new this.BMap.Size(16, 32)
          }),
        title: k.PointName
      })
      marker.setMap(map)
      marker.extData = k.PointID
      marker.getExtData = function () {
        return this.extData
      }

      marker.on('click', () => {
        const d = k
        let panel = this.getSimplePanel(d.PointName, [{
          title: `${this.$t('onlineMonitor.lngLat')}`,
          text: d.Lon + ',' + d.Lat
        }], '5em', {
          links: [this.$t('onlineMonitor.removeFavirite')],
          onLinkClick: () => {
            this.deletePoi(d)
          },
          lon: d.Lon,
          lat: d.Lat
        })

        if (this.glBmap) {
          this.createInfowindow(panel)
          this.infoWindow.open(marker.getPosition())
          this.infoWindow.id = null
        } else {
          this.infoWindow.setContent(panel)
          this.infoWindow.open(this.map, marker.getPosition())
          this.infoWindow.setId(null)
        }
      })
      this.poiOverlays.push(marker)
      this.setFitView_bd(this.map, [marker])
    },
    addPoi(map) {
      map = map || this.map
      API.GetPOI('', true).then(ret => {
        const list = ret.data.splice(0, 500)
        this.removePoi()
        list.forEach((k, index) => {
          if (this.currentMap === 'Bmap') {
            let marker = new this.BMap.Marker(new this.BMap.Point(k.Lon_F, k.Lat_F), {
              icon: new this.BMap.Icon(require('../../assets/icon/shadow_marker.png'),
                new this.BMap.Size(32, 32), {
                  anchor: new this.BMap.Size(16, 32)
                }),
              title: k.PointName
            })
            marker.setMap(map)
            marker.extData = k.PointID
            marker.getExtData = function () {
              return this.extData
            }
            marker.on('click', () => {
              const d = list[index]
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}`,
                text: d.Lon_F + ',' + d.Lat_F
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon_F,
                lat: d.Lat_F
              }))
              this.infoWindow.open(this.map, marker.getPosition())
              this.infoWindow.setId(null)
            })
            this.poiOverlays.push(marker)
          } else {
            let marker = new this.AMap.Marker({
              map: map,
              position: [k.Lon_F, k.Lat_F],
              extData: k.PointID,
              icon: require('../../assets/icon/shadow_marker.png'),
              // content: `<i class="el-icon-star-on" style="color:red;font-size:24px;"></i>`,
              offset: new this.AMap.Pixel(-16, -32),
              label: {
                content: k.PointName,
                offset: new this.AMap.Pixel(32, 8)
              }
            })
            marker.on('click', () => {
              const d = list[index]
              this.infoWindow.setContent(this.getSimplePanel(d.PointName, [{
                title: `${this.$t('onlineMonitor.lngLat')}`,
                text: d.Lon_F + ',' + d.Lat_F
              }], '5em', {
                links: [this.$t('onlineMonitor.removeFavirite')],
                onLinkClick: () => {
                  this.deletePoi(d)
                },
                lon: d.Lon_F,
                lat: d.Lat_F
              }))
              this.infoWindow.open(this.map, marker.getPosition())
              this.infoWindow.setId(null)
              if (this.glAmap) {
                this.infoWindow.setAnchor('bottom-center')
              }
            })
            this.poiOverlays.push(marker)
          }

        })
      })
    },
    savePoi() {
      const handler = async e => {
        this.map.off('click', handler)
        let err = this.$t('onlineMonitor.savePoiTitle')
        let err2 = this.$t('onlineMonitor.savePoiTip2')
        const {
          value
        } = await this.$prompt(this.$t('onlineMonitor.savePoiTitle'), this.$t('onlineMonitor.savePoiTip'), {
          confirmButtonText: this.$t('common.save'),
          cancelButtonText: this.$t('common.cancel'),
          inputValidator(val) {
            if (!val) {
              return err
            } else if (val.length > 50) {
              return err2
            } else {
              return true
            }
          }
        })
        const poi = {
          HoldID: -1,
          Lon_F: this.currentMap === 'Bmap' ? e.point.lng : e.lnglat.getLng(),
          Lat_F: this.currentMap === 'Bmap' ? e.point.lat : e.lnglat.getLat(),
          PointName: value,
          PointType: 5,
          Remark: ''
        }
        API.AddPoi(poi).then(ret => {
          this.selectPoi(ret.data)
          this.$message({
            message: this.$t('onlineMonitor.saveSuccessed'),
            type: 'success'
          })
        })
      }
      this.map.on('click', handler, this)
      this.$message(this.$t('onlineMonitor.savePoiTip3'))
    },
    removeRegion() {
      this.regionOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.regionOverlays.splice(0)
      this.regionOverlaysJuhe.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.regionOverlaysJuhe.splice(0)
    },
    removePoi() {
      this.poiOverlays.forEach(k => {
        k.setMap(null)
        k = null
      })
      this.poiOverlays.splice(0)
    },
    deletePoi(item) {
      API.DeletePoi([item.PointID]).then(() => {
        this.$message({
          message: this.$t('onlineMonitor.deletePoiTip', {
            name: item.PointName
          }),
          type: 'success'
        })
        this.map.clearInfoWindow()
        let target = findFromArray(this.poiOverlays, k => k.getExtData() === item.PointID)
        if (target) {
          target.setMap(null)
          target = null
        }
      })
    },
    removeAllData(res) {
      this.end_timer()
      if (this.glBmap) {
        this.infoWindow.close()
      }
      this.list.forEach(k => {
        k.marker = null
        k.marker_text = null
        k.overlays.forEach(j => {
          j.setMap && j.setMap(null)
        })
      })
      this.list.splice(0)
      this.listItem = []
      this.alarmList.splice(0)
      this.delTaskAllDevice()
      // this.map&&this.map.clearMap()
    },
    removeAllData2() {
     
      if (this.glBmap) {
        this.infoWindow.close()
      }
      this.list.forEach(k => {
        k.marker = null
        k.marker_text = null
        k.overlays.forEach(j => {
          j.setMap && j.setMap(null)
        })
      })
      this.list.splice(0)
      this.listItem = []
      this.alarmList.splice(0)
      this.map&&this.map.clearMap()
    },
    startFindRect() {
      this.removeAllData()
      this.isOnFindByRect = true

      if (this.mousetool) {
        this.mousetool.rectangle()
        return
      }
      this.map.plugin(["AMap.MouseTool"], () => {
        let mousetool = new this.AMap.MouseTool(this.map)
        mousetool.rectangle()
        mousetool.on('draw', async ({
          obj
        }) => {
          // this.isOnFindByRect = false
          let points = []
          if (this.glAmap) {
            let path = obj.aE[0][0]
            points = path.map(j => ({
              lng: j[0],
              lat: j[1]
            }))
          } else {
            points = obj.getPath()
          }
          const minLon = Math.min(...points.map(k => k.lng))
          const maxLon = Math.max(...points.map(k => k.lng))
          const minLat = Math.min(...points.map(k => k.lat))
          const maxLat = Math.max(...points.map(k => k.lat))
          // 查找
          this.isOnFindByRectLoading = true
          const list = await this.fetchByRect(minLon, minLat, maxLon, maxLat)
          this.isOnFindByRectLoading = false
          this.isOnFindByRect = false
          mousetool.close(true)
          obj.setMap(null)
          list.forEach(k => this.addDataToMap(k))
        })
        this.mousetool = mousetool
      })
    },
    startFindRect_bd() {
      this.removeAllData()
      this.isOnFindByRect = true

      if (this.mousetool) {
        this.mousetool.open()
        return
      }

      let mousetool = new window.BMapLib.DrawingManager(this.map, {
        isOpen: true,
        enableDrawingTool: false,
        rectangleOptions: {
          strokeColor: "#5DB1FA", //边线颜色。
          fillColor: "#5DB1FA", //填充颜色。当参数为空时，圆形将没有填充效果。
          strokeWeight: 2, //边线的宽度，以像素为单位。
          strokeOpacity: 0.8, //边线透明度，取值范围0 - 1。
          fillOpacity: 0.3, //填充的透明度，取值范围0 - 1。
          strokeStyle: 'solid' //边线的样式，solid或dashed。
        }
      })
      mousetool.setDrawingMode('rectangle')
      mousetool.open()
      mousetool.addEventListener('overlaycomplete', async e => {
        let obj = e.overlay
        const points = obj.getPath()
        const minLon = Math.min(...points.map(k => k.lng))
        const maxLon = Math.max(...points.map(k => k.lng))
        const minLat = Math.min(...points.map(k => k.lat))
        const maxLat = Math.max(...points.map(k => k.lat))
        // 查找
        this.isOnFindByRectLoading = true
        const list = await this.fetchByRect(minLon, minLat, maxLon, maxLat)
        this.isOnFindByRectLoading = false
        obj.setMap(null)
        list.forEach(k => this.addDataToMap(k))
        mousetool.close()
        this.isOnFindByRect = false
      })
      this.mousetool = mousetool
    },
    endFindRect() {
      this.isOnFindByRect = false
      this.mousetool.close(true)
      this.mousetool = null
    },
    toggle_ruler() {
      if (this.mapTool.ruler) {
        if (this.currentMap === 'Bmap') {
          this.toggle_ruler__end_bd()
        } else {
          this.toggle_ruler__end()
        }
      } else {
        this.toggle_ruler__start()
      }
    },
    toggle_ruler__start() {
      this.map.plugin(['AMap.RangingTool'], () => {
        this.mapTool.ruler = new this.AMap.RangingTool(this.map)
        this.mapTool.ruler.turnOn()
        this.AMap.event.addListener(this.mapTool.ruler, "end", () => {
          this.mapTool.ruler.turnOff()
          this.mapTool.ruler = null
        })
      })
    },
    toggle_ruler__start_bd() {
      this.mapTool.ruler = new window.BMapLib.DistanceTool(this.map)
      this.mapTool.ruler.open();
      this.mapTool.ruler.addEventListener('ondrawend', () => {
        this.mapTool.ruler = null
      })
    },
    toggle_ruler__end() {
      this.mapTool.ruler.turnOff()
      this.mapTool.ruler = null
    },
    toggle_ruler__end_bd() {
      this.mapTool.ruler.close()
      this.mapTool.ruler = null
    },
    setFitView(map, markers) {
      map.setFitView(markers)
    },
    setFitView_bd(map, markers) {
      let points = []

      if (markers instanceof Array) {
        for (let i = 0; i < markers.length; i++) {
          let k = markers[i]
          // console.log(k)
          if (k instanceof this.BMap.Marker) {
            points.push(k.getPosition())
          } else if (k instanceof this.BMap.Polygon) {
            let pos = k.getPath()
            points = points.concat(pos)
          }
        }
      }

      //console.log(markers, points)
      if (points.length) {
        this.map.setViewport(points)
      }
    },
    mapPlaceSelect(lng, lat) {
      let location = [lng, lat]
      if (window.BMap) {

        API.ConvertFrom([{
          lng,
          lat
        }], 'mars', 'bd').then(ret => {
          lng = ret.data[0].lng
          lat = ret.data[0].lat
          this.map.setZoom(15)
          this.map.setCenter(new window.BMap.Point(lng, lat))
          if (this.mapPlaceSelectMarker) {
            this.mapPlaceSelectMarker.setPosition(new this.BMap.Point(lng, lat))
          } else {
            this.mapPlaceSelectMarker = new this.BMap.Marker(new this.BMap.Point(lng, lat))
            this.mapPlaceSelectMarker.setMap(this.map)
          }
        })

      } else if (window.AMap) {
        this.map.setZoomAndCenter(15, location)
        if (this.mapPlaceSelectMarker) {
          this.mapPlaceSelectMarker.setPosition(location);
        } else {
          this.mapPlaceSelectMarker = new window.AMap.Marker({
            position: location,
            map: this.map,
            title: this.$t('onlineMonitor.clickRightRemove')
          })
          this.mapPlaceSelectMarker.on('rightclick', () => {
            this.mapPlaceSelectMarker.setMap(null)
            this.mapPlaceSelectMarker = null
          })
        }
      }
    },
    mapPlaceSelect2(lng, lat) {
      // let location = [lng, lat]
      API.ConvertFrom([{
        lng,
        lat
      }], 'mars', 'bd').then(ret => {
        lng = ret.data[0].lng
        lat = ret.data[0].lat
        if (this.mapPlaceSelectMarker) {
          this.mapPlaceSelectMarker.setMap(null)
        }
        this.mapPlaceSelectMarker = new this.BMap.Marker(new this.BMap.Point(lng, lat))
        this.mapPlaceSelectMarker.setMap(this.map)
        this.map.centerAndZoom(new this.BMap.Point(lng, lat), 15)

      })
    },
    refreshMap(status, center = [116.397428, 39.90923], zoom = 11) {
      const promise = new Promise(async resolve => {
        // 加载位置
        const position = this.loadPosition()
        center = position.center
        zoom = position.zoom
        let layer = null
        let map = null
        this.infoWindow = null
        this.poiOverlays = []
        this.regionOverlays = []
        let currentMap = this.currentMap
        let amap3d = this.glAmap
        let bmap3d = this.glBmap
        this.map = null
        if (currentMap === 'Bmap') {
          if (bmap3d) {
            if (!window.BMapGL) {
              window.BMapGL_loadScriptTime = (new Date).getTime()
              window.BMapGL_PROTOCOL = window.location.protocol.replace(':', '')
              await remoteLoad(this.$BMAP_URL_GL)
            }
            if (!window.BMapGLLib) {
              await remoteLoad('https://mapopen.bj.bcebos.com/github/BMapGLLib/InfoBox/src/InfoBox.js')
              await remoteLoad('http://mapopen.bj.bcebos.com/github/BMapGLLib/DistanceTool/src/DistanceTool.js')
              await remoteLoad('http://mapopen.bj.bcebos.com/github/BMapGLLib/DrawingManager/src/DrawingManager.min.js')
            }

            map = new window.BMapGL.Map('containerBmap', {
              enableMapClick: false
            })
            map.centerAndZoom(new window.BMapGL.Point(116.280190, 40.049191), 4); // 初始化地图,设置中心点坐标和地图级别

            map.enableScrollWheelZoom(true)
            map.enableContinuousZoom()
            this.map = map
            this.map.setMapType(BMAP_EARTH_MAP);
            this.BMap = window.BMapGL
            let navi3DCtrl = new window.BMapGL.NavigationControl3D(); // 添加3D控件
            this.map.addControl(navi3DCtrl);
            this.ifNotExistsWindowBd2()
          } else {
            // 加载百度js
            // if (!window.BMap) {
            //   window.BMap_loadScriptTime = (new Date).getTime()
            //   window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
            //   await remoteLoad(this.$BMAP_URL)
            // }
            // if (!window.BMapLib) {
            //   await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
            //   await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
            //   await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
            //   await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
            // }
            window.BMap_loadScriptTime = (new Date).getTime()
            window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
            await remoteLoad(this.$BMAP_URL)
            await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
            await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
            await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
            await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
            map = new window.BMap.Map(this.$refs.container, {
              enableMapClick: false
            })
            map.centerAndZoom(new window.BMap.Point(center[0], center[1]), zoom)
            map.enableScrollWheelZoom(true)
            map.enableContinuousZoom()
            map.clearHotspots()
            // map.setMapStyleV2({
            //   styleId: '8c5ff7c6a9553d0b46f865ae9ff147b7'
            // })
            let mapType = new window.BMap.MapTypeControl({
              mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
              anchor: window.BMAP_ANCHOR_TOP_RIGHT
            })
            map.addControl(mapType)
            this.map = map
            this.BMap = window.BMap
            this.ifNotExistsWindowBd()
          }
          // 初始化地图


          // 清空地图
          this.BMap.Map.prototype.clearMap = this.BMap.Map.prototype.clearOverlays
          this.BMap.Map.prototype.on = this.BMap.Map.prototype.addEventListener
          this.BMap.Map.prototype.off = this.BMap.Map.prototype.removeEventListener
          // 关闭信息窗体
          this.BMap.Map.prototype.clearInfoWindow = this.BMap.Map.prototype.closeInfoWindow
          // 打开信息窗体
          this.BMap.InfoWindow.prototype.open = function (m, p) {
            p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
            m.openInfoWindow(this, p)
          }
          // 关闭信息窗体
          this.BMap.InfoWindow.prototype.close = () => this.map.closeInfoWindow()
          // 信息窗体设置位置
          this.BMap.InfoWindow.prototype.setPosition = function (p) {
            p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
            this.isOpen() && map.openInfoWindow(this, p)
          }
          // Overlay属性
          this.BMap.Overlay.prototype.setMap = function (m) {
            m ? m.addOverlay(this) : (this.getMap() && this.getMap().removeOverlay(this))
          }
          this.BMap.Overlay.prototype.on = this.BMap.Overlay.prototype.addEventListener
          this.BMap.Overlay.prototype.off = this.BMap.Overlay.prototype.removeEventListener
          // Point属性
          this.BMap.Point.prototype.getLng = function () {
            return this.lng
          }
          this.BMap.Point.prototype.getLat = function () {
            return this.lat
          }
        } else if (currentMap === 'Google') {
          await remoteLoad(this.$AMAP_URL)
          await remoteLoad('//webapi.amap.com/ui/1.1/main.js?v=1.1')
          layer = new window.AMap.TileLayer({
            getTileUrl: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
            zIndex: 2
          })
          this.mapLayer = layer
          map = new window.AMap.Map(this.$refs.container, {
            resizeEnable: true,
            zoom,
            center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 20],
            layers: [layer],
            expandZoomRange: true
          })
          this.map = map
          this.AMap = window.AMap
          this.ifNotExistsWindow()
        } else if (currentMap === 'QQmap') {
          await remoteLoad(this.$AMAP_URL)
          await remoteLoad('//webapi.amap.com/ui/1.1/main.js?v=1.1')
          layer = new window.AMap.TileLayer({
            getTileUrl(x, y, z) {
              let ty = Math.pow(2, z) - 1 - y
              return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(ty/16.0)}/${x}_${ty}.png?version=20190101`
            },
            zIndex: 2
          });
          this.mapLayer = layer
          map = new window.AMap.Map(this.$refs.container, {
            resizeEnable: true,
            zoom,
            center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 20],
            layers: [layer],
            expandZoomRange: true
          })
          this.map = map
          this.AMap = window.AMap
          this.ifNotExistsWindow()
        } else if (currentMap === 'Openstreet') {
          await remoteLoad(this.$AMAP_URL)
          await remoteLoad('//webapi.amap.com/ui/1.1/main.js?v=1.1')
          layer = new window.AMap.TileLayer({
            getTileUrl(x, y, z) {
              return `//a.tile.openstreetmap.org/${z}/${x}/${y}.png`
            },
            zIndex: 2
          })
          this.mapLayer = layer
          map = new window.AMap.Map(this.$refs.container, {
            resizeEnable: true,
            zoom,
            center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 20],
            layers: [layer],
            expandZoomRange: true
          })
          this.map = map
          this.AMap = window.AMap
          this.ifNotExistsWindow()
        } else if (currentMap === 'Bing') {
          await remoteLoad(this.$AMAP_URL)
          await remoteLoad('//webapi.amap.com/ui/1.1/main.js?v=1.1')
          layer = new window.AMap.TileLayer({
            getTileUrl: (x, y, z) => {
              let code = new Array(z)
              let nex = Math.pow(2, z - 1)
              for (let i = 0; i < code.length; i++) {
                let ox = Math.floor(x / nex) % 2
                let oy = (Math.floor(y / nex) % 2) * 2
                code[i] = ox + oy
                nex = nex / 2
              }
              let imgNumber = code.join('')
              return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
            },

            zIndex: 2
          });
          map = new window.AMap.Map(this.$refs.container, {
            resizeEnable: true,
            zoom,
            center,
            isHotspot: false,
            features: [],
            preloadMode: false,
            zooms: [3, 20],
            layers: [layer],
            expandZoomRange: true
          })
          this.map = map
          this.AMap = window.AMap
          this.ifNotExistsWindow()
        } else {
          await remoteLoad(this.$AMAP_URL)
          this.$nextTick(() => {
            // if (amap3d) {
            //   map = new window.AMap.Map(this.$refs.container, {
            //     rotateEnable: true,
            //     pitchEnable: true,
            //     pitch: 50,
            //     viewMode: '3D', //开启3D视图,默认为关闭
            //     buildingAnimation: true,
            //     zoom,
            //     center,
            //     isHotspot: false,
            //     zooms: [3, 20],
            //     expandZoomRange: true
            //   })
            // } else {
            //   map = new window.AMap.Map(this.$refs.container, {
            //     resizeEnable: true,
            //     zoom,
            //     center,
            //     isHotspot: false,
            //     // layers: [layer],
            //     zooms: [3, 20],
            //     expandZoomRange: true
            //   })
            // }
            map = new window.AMap.Map(this.$refs.container, {
              resizeEnable: true,
              zoom,
              center,
              isHotspot: false,
              // layers: [layer],
              zooms: [3, 20],
              expandZoomRange: true
            })
            this.map = map
            this.AMap = window.AMap
            this.AMapUI = window.AMapUI
            this.ifNotExistsWindow()
          })
        }
        resolve()
      })
      return promise
    },
    changeMap() {
      const promise = new Promise(async resolve => {
        console.log(this.glAmap)
        const position = this.loadPosition()
        let center = position.center
        let zoom = position.zoom
        let map = null
        if (this.glAmap) {
          await remoteLoad(this.$AMAP_URL_GL)
          map = new window.AMap.Map("id1", {
            rotateEnable: true,
            pitchEnable: true,
            pitch: 50,
            viewMode: '3D', //开启3D视图,默认为关闭
            buildingAnimation: true,
            zoom,
            center,
            isHotspot: false,
            zooms: [3, 20],
            expandZoomRange: true
          })
          this.map = map
          this.AMap = window.AMap
          this.AMapUI = window.AMapUI
          this.ifNotExistsWindow()
        } else {
          this.refreshMap()
        }

        resolve()
      })
      return promise
    },
    initMap(center = [116.397428, 39.90923], zoom = 11) {
      const promise = new Promise(async resolve => {
        // 加载位置
        const position = this.loadPosition()
        center = position.center
        zoom = position.zoom
        console.log('页面加载地图类型' + this.currentMap)
        if (this.currentMap === 'Bmap') {
          // 加载百度js
          if (!window.BMap) {
            window.BMap_loadScriptTime = (new Date).getTime()
            window.BMAP_PROTOCOL = window.location.protocol.replace(':', '')
            await remoteLoad(this.$BMAP_URL)
          }

          if (!window.BMapLib) {
            await remoteLoad('//api.map.baidu.com/library/DistanceTool/1.2/src/DistanceTool_min.js')
            await remoteLoad('//api.map.baidu.com/library/DrawingManager/1.4/src/DrawingManager_min.js')
            await remoteLoad('//api.map.baidu.com/library/MarkerClusterer/1.2/src/MarkerClusterer_min.js')
            await remoteLoad('//api.map.baidu.com/library/TextIconOverlay/1.2/src/TextIconOverlay_min.js')
          }

          // 初始化地图
          let map = new window.BMap.Map(this.$refs.container, {
            enableMapClick: false
          })
          map.centerAndZoom(new window.BMap.Point(center[0], center[1]), zoom)
          map.enableScrollWheelZoom(true)
          map.enableContinuousZoom()
          map.clearHotspots()
          // map.setMapStyleV2({
          //   styleId: '8c5ff7c6a9553d0b46f865ae9ff147b7'
          // })
          let mapType = new window.BMap.MapTypeControl({
            mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_HYBRID_MAP],
            anchor: window.BMAP_ANCHOR_TOP_RIGHT
          })
          map.addControl(mapType)
          this.map = map
          this.BMap = window.BMap
          // 清空地图
          this.BMap.Map.prototype.clearMap = this.BMap.Map.prototype.clearOverlays
          this.BMap.Map.prototype.on = this.BMap.Map.prototype.addEventListener
          this.BMap.Map.prototype.off = this.BMap.Map.prototype.removeEventListener
          // 关闭信息窗体
          this.BMap.Map.prototype.clearInfoWindow = this.BMap.Map.prototype.closeInfoWindow
          // 打开信息窗体
          this.BMap.InfoWindow.prototype.open = function (m, p) {
            p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
            m.openInfoWindow(this, p)
          }
          // 关闭信息窗体
          this.BMap.InfoWindow.prototype.close = () => this.map.closeInfoWindow()
          // 信息窗体设置位置
          this.BMap.InfoWindow.prototype.setPosition = function (p) {
            p = p instanceof Array ? new window.BMap.Point(p[0], p[1]) : p
            this.isOpen() && map.openInfoWindow(this, p)
          }
          // Overlay属性
          this.BMap.Overlay.prototype.setMap = function (m) {
            m ? m.addOverlay(this) : (this.getMap() && this.getMap().removeOverlay(this))
          }
          this.BMap.Overlay.prototype.on = this.BMap.Overlay.prototype.addEventListener
          this.BMap.Overlay.prototype.off = this.BMap.Overlay.prototype.removeEventListener
          // Point属性
          this.BMap.Point.prototype.getLng = function () {
            return this.lng
          }
          this.BMap.Point.prototype.getLat = function () {
            return this.lat
          }
          this.ifNotExistsWindowBd()
        } else {
          if (!window.AMap) {
            await remoteLoad(this.$AMAP_URL)
          }
          if (!window.AMapUI) {
            await remoteLoad('//webapi.amap.com/ui/1.1/main.js?v=1.1')
          }
          if (this.currentMap === 'QQmap') {
            this.mapLayer = new window.AMap.TileLayer({
              getTileUrl(x, y, z) {
                let ty = Math.pow(2, z) - 1 - y
                return `//p3.map.gtimg.com/maptilesv2/${z}/${Math.floor(x/16.0)}/${Math.floor(ty/16.0)}/${x}_${ty}.png?version=20190101`
              },
              zIndex: 2
            })
          } else if (this.currentMap === 'Google') {
            this.mapLayer = new window.AMap.TileLayer({
              getTileUrl: '//47.88.25.10:10808/vt/lyrs=m@142&hl=zh-CN&gl=cn&src=app&x=[x]&y=[y]&z=[z]&s=Ga',
              zIndex: 2
            })
          } else if (this.currentMap === 'Bing') {
            this.mapLayer = new window.AMap.TileLayer({
              getTileUrl: (x, y, z) => {
                let code = new Array(z)
                let nex = Math.pow(2, z - 1)
                for (let i = 0; i < code.length; i++) {
                  let ox = Math.floor(x / nex) % 2
                  let oy = (Math.floor(y / nex) % 2) * 2
                  code[i] = ox + oy
                  nex = nex / 2
                }
                let imgNumber = code.join('')
                return `https://t1.dynamic.tiles.ditu.live.com/comp/ch/${imgNumber}?mkt=${this.$i18n.locale=="zh" ? "zh-CN": "en-US"}&ur=CN&it=G,TW,L&n=z&og=5&cstl=rd`
              },

              zIndex: 2
            });
          } else if (this.currentMap === 'Openstreet') {
            this.mapLayer = new window.AMap.TileLayer({
              getTileUrl(x, y, z) {
                return `//a.tile.openstreetmap.org/${z}/${x}/${y}.png`
              },
              zIndex: 2
            })
          } else {
            this.mapLayer = new window.AMap.TileLayer({
              getTileUrl: this.getLayerTile,
              zIndex: 2
            });
          }

          var satellite =  new window.AMap.TileLayer.Satellite();
          
          let map = new window.AMap.Map(this.$refs.container, {
            resizeEnable: true,
            buildingAnimation: true,
            zoom,
            center,
            isHotspot: false,
            layers: [satellite],
            zooms: [3, 20],
            expandZoomRange: true
          })
          // let mimap = new window.AMap.Map(this.$refs.mimap, {
          //   resizeEnable: true,
          //   buildingAnimation: true,
          //   zoom,
          //   center,
          //   isHotspot: false,
          //   layers: [this.mapLayer],
          //   zooms: [3, 20],
          //   expandZoomRange: true
          // })

          // let overView = new window.AMap.OverView({
          //     visible: true
          // })
          // overView.open();
          // map.addControl(overView)
          window.AMapUI.loadUI(['control/BasicControl'], BasicControl => {
            this.LayerSwitcherControl = new BasicControl.LayerSwitcher({
              position: {
                top: '8px',
                right: '10px'
              }
            })
            map.addControl(this.LayerSwitcherControl)
          })
          this.map = map
          // this.mimap = mimap
          this.AMap = window.AMap
          this.ifNotExistsWindow()

        }
        resolve()
      })
      return promise
    }
  },
  computed: {
    ...mapGetters([
      'currentMap',
      'glBmap',
      'glAmap'
    ])
  },
  components: {
    // DetailVehicle,
    // MapPlace,
    // DetailDevice
  }
}
