<template>
  <div class="v-block-game">
    <div class="v-tit" v-show="visible" @click="handlerOpen">
      <img :src="require('../../assets/images/gs-tit1.png')" alt="" />
      <h2>赛况</h2>
    </div>
    <div class="v-content" v-show="!visible">
      <div class="v-content-top">
        <div class="tagTop">
          <img :src="require('../../assets/images/gs-g4.png')" alt="" />
        </div>
      </div>
      <div class="v-content-center">
        <div class="tit">
          <h3>赛况</h3>
          <span class="ico" @click="handlerClose"><i class="el-icon-close"></i></span>
        </div>
        <div class="tab">
          <div
              :class="item.type === current ? 'cell active' : 'cell'"
              v-for="(item, index) in options"
              :key="index"
              @click="handlerClk(item.type)"
            >
              <img :src="require('../../assets/images/gs-g1.png')" alt="" v-if="item.type !== current"/>
              <img :src="require('../../assets/images/gs-g2.png')" alt="" v-else/>
              <span>{{ item.value }}</span>
            </div>
          </div>
        <ul class="list" v-show="current === 1">
          <el-scrollbar wrap-class="content-body-scroll">
            <li class="caption">
              <span>参赛队员</span>
              <strong>状态</strong>
            </li>
            <li v-for="(item, index) in dataList" :key="index">
              <span>{{ item.Name }}</span>
              <strong>到达</strong>
            </li>
          </el-scrollbar>
        </ul>
        <ul class="list" v-show="current === 2">
          <el-scrollbar wrap-class="content-body-scroll">
            <li class="caption">
              <span>队伍名称</span>
              <strong>配速</strong>
            </li>
            <li v-for="(item, index) in trackList" :key="index">
              <span>{{ item.holdName }}</span>
              <strong>{{ item.speed }}</strong>
            </li>
          </el-scrollbar>
        </ul>
      </div>
      <div class="v-content-bottom">
        <div class="tagBot">
          <img :src="require('../../assets/images/gs-g6.png')" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { POST_URL, DOWNLOAD_URL } from "@/api/config";
import { mapGetters } from "vuex";
import { GetRaceEnd, GetRealtimeSpeed } from "@/api/common";
export default {
  props: {
    upgrade: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      visible: true,
      options: [
        {
          type: 1,
          value: "完赛情况",
        },
        {
          type: 2,
          value: "实时配速",
        },
      ],
      current: 2, // 当前面板 1
      dataList: [],
      trackList: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["loginInfo", "currentHoldId"]),
  },
  watch: {
    upgrade(form, to) {
      if (form & (form !== to)) {
        if (form === 1) {
          this.fetchData();
        }
      }
    },
  },
  methods: {
    handlerOpen(){
      console.log('1')
      this.visible = false;
    },
    handlerClose(){
      console.log('2')
      this.visible = true;
    },
    open() {
      this.visible = true;
      this.fetchData();
    },
    handlerClk(e) {
      this.current = e;
      this.fetchData();
    },
    fetchData() {
      if (this.current === 1) {
        this.fetchRaceEnd();
      } else {
        this.fetchRealtimeSpeed();
      }
    },
    fetchRaceEnd() {
      this.loading = true;
      GetRaceEnd()
        .then((ret) => {
          this.dataList = ret.data.list;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchRealtimeSpeed() {
      this.loading = true;
      GetRealtimeSpeed()
        .then((ret) => {
          this.trackList = ret.data.retList;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.v-block-game {
  position: absolute;
  left: 30px;
  top: 7px;
  z-index: 1;
  .v-content-top{
    width: 100%;
    height: 19px;
    position: relative;
    z-index: 1;
    .tagTop {
      width: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: -1;
      img{
        height: 100%;
      }
    }
  }
  .v-content-center{
    width: 100%;
    height: auto;
    background: url(../../assets/images/gs-g5.png) repeat-y;
    background-size: 100% auto;
  }
  .v-content-bottom{
    height: 20px;
    position: relative;
    z-index: 1;
    .tagBot {
      position: absolute;
      bottom: -5px;
      left: 0px;
      z-index: 2;
    }
  }
  .v-tit {
    width: 100%;
    height: 40px;
    position: relative;
    cursor: pointer;
    h2 {
      font-size: 16px;
      line-height: 40px;
      color: #fff;
      text-indent: 10px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .v-content {
    width: 248px;
  }
  

  .tit {
    width: 100%;
    padding-top: 15px;
    // background-color: #000000;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    text-align: center;
    position: relative;
    z-index: 1;

    h3 {
      font-size: 20px;
      color: #fff;
    }

    .ico {
      position: absolute;
      right: 5px;
      top: 5px;
      z-index: 1;
      color: #fff;
      padding: 8px;
      font-weight: 700;
      cursor: pointer;
    }

    .ico:hover {
      color: #ccc;
    }
  }
  .tab {
    display: flex;
    height: auto;
    overflow: hidden;
    // background-color: #000000;
    padding: 12px 18px 14px 18px;
    .cell {
      width: 50%;
      height: 30px;
      text-align: center;
      color: #fff;
      cursor: pointer;
      padding-bottom: 10px;
      position: relative;
      img{
        width: 100%;
        height: 30px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
      span{
        font-size: 16px;
        line-height: 30px;
        position: relative;
        z-index: 1;
      }
    }
    // .active {
    //   background-color: #2e4961;
    // }
  }

  .list {
    // background-color: #000000;
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    min-height: 300px;
    max-height: 600px;

    li {
      display: flex;
      padding: 0 15px;
      font-size: 14px;
      height: 40px;
      line-height: 40px;

      span {
        width: 170px;
        overflow: hidden;
        font-weight: 400;
        color: #ffffff;
      }

      strong {
        color: #8cfff8;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.42);
      }
    }

    .caption {
      height: 20px;
      line-height: 20px;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #858585;
      }

      strong {
        font-size: 12px;
        font-weight: 400;
        color: #858585;
      }
    }
  }
}
</style>
